<template>
  <div class="settings-general">
    <v-form v-model="formValid" @submit.prevent="submit" class="mt-10">
      <!-- Username -->
      <v-text-field
        v-model="formData.username"
        @input="
          usernameValid = false;
          checkUsername();
        "
        :label="$t('common.username')"
        :loading="$loading('auth/usernameValid')"
        :maxlength="$config.username.maxLength"
        :rules="[
          $rules.username,
          !$loading('auth/usernameValid') || $t('register.checkingUsername'),
          usernameValid || $t('register.usernameTaken'),
        ]"
        filled
        background-color="ocean-dark"
      />

      <!-- Email -->
      <v-text-field
        v-model="formData.email"
        :label="$t('common.email')"
        type="email"
        filled
        :rules="[$rules.email]"
        background-color="ocean-dark"
      />

      <!-- Clubs -->
      <v-select
        filled
        :label="$t('register.onboard.favouriteFootballClub')"
        v-model="formData.favourite_football_club_id"
        :items="$store.getters['clubs/all']"
        :loading="$loading('clubs/fetchAll')"
        item-text="name"
        item-value="id"
        class="select__favourite_clubs"
        menu-props="offsetY"
      />

      <api-response :response="formResponse" class="mt-3" />

      <div class="d-flex justify-end mt-5">
        <!-- Save button -->
        <v-btn
          color="primary"
          type="submit"
          :disabled="!formValid"
          :loading="$loading('auth/updateUser')"
          rounded
        >
          {{ $t("common.save") }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import debounce from "@/utils/debounce";

export default {
  data: () => ({
    formData: {},
    formValid: false,
    formResponse: null,
    usernameValid: true,
  }),

  created() {
    this.initForm();
    this.$store.dispatch("clubs/fetchAll");
  },

  methods: {
    initForm() {
      const user = Object.assign({}, this.$user);

      this.formData = {
        email: user.email,
        username: user.username,
        favourite_football_club_id: user.favourite_football_club_id,
      };
    },

    submit() {
      this.formResponse = null;
      this.$store
        .dispatch("auth/updateUser", this.getPayload())
        .then(() => {
          this.$toast.success(this.$t("common.success"));
        })
        .catch((err) => {
          this.formResponse = err.data;
        });
    },

    checkUsername: debounce(function () {
      this.usernameValid = false;

      if (this.formData.username.length < 4) {
        return;
      }

      if (
        this.formData.username.toLowerCase() ===
        this.$user.username.toLowerCase()
      ) {
        this.usernameValid = true;
        return;
      }

      this.$store
        .dispatch("auth/usernameValid", this.formData.username)
        .then((res) => {
          this.usernameValid = res.username_valid;
        });
    }, 500),

    getPayload() {
      const payload = Object.assign({}, this.formData);

      for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
          const element = payload[key];
          if (element === this.$user[key]) {
            delete payload[key];
          }
        }
      }
      return payload;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep .select__favourite_clubs .v-input__slot {
  background-color: #08121f !important;
}
</style>
